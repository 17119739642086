export function buildAutoBreadcrumb(part: any, localePath: any, withVariant = false) {
  let l = [];
  if (part?.make) l.push({ label: part.make.name, to: localePath({ name: 'parts-search-requests', query: { make_id: part.make.id }})})
  if (part?.model) l.push({ label: part.model.name, to: localePath({ name: 'parts-search-requests', query: { make_id: part.make.id, model_id: part.model.id }})})
  if (part?.generation) l.push({ label: part.generation.name, to: localePath({ name: 'parts-search-requests', query: { make_id: part.make.id, model_id: part.model.id, generation_id: part.generation.id }})})
  if (withVariant && part?.variant) l.push({ label: part.variant.name, to: localePath({ name: 'parts-search-requests', query: { make_id: part.make.id, model_id: part.model.id, generation_id: part.generation.id, variant_id: part.variant.id }})})
  return l;
}

export function buildCategoryBreadcrumb(part: any, localePath: any) {
  let l = [];
  if (part?.category) l.push({ label: part.category.name, to: localePath({ name: 'parts-search-requests', query: { category_id: part.category.id }})})
  if (part?.subcategory) l.push({ label: part.subcategory.name, to: localePath({ name: 'parts-search-requests', query: { category_id: part.subcategory.id, subcategory_id: part.subcategory.id }})})
  return l;
}
